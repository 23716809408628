import React, { useContext, useState } from "react";
import { Label, ShopCard, TreeWizard } from "@3beehivetech/components";
import { format } from "date-fns";
import useTranslation from "../../hooks/useTranslation";
import { Buffer } from "buffer";
import { LayoutContext } from "../../contexts/LayoutContext";
import { DataContext } from "../../contexts/DataContext";
import styled from "styled-components";
import { toEuro } from "../../utils/toEuro";
import { StyledCard } from "./style";

const SLabel = styled(Label)`
  /* col-span-4 */
  grid-column: span 4/span 4;
  /* bg-[#9CC500] */
  background-color: #9CC500;
`;

const SItem = styled.div`
  /* col-span-4 */
  grid-column: span 4/span 4;
`;

const FedezCard = (props) => {
  const { t } = useTranslation();
  const toBase64Url = (str) => new Buffer.from(str).toString("base64");
  const { languages } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";
  const { hive: fedezHive } = props;
  const planID = 56;

  const { setModalContent } = useContext(LayoutContext);
  
  const onSubmit = (data) => {
    const formattedSendDate = format(
      data.gift.sendDate ?? new Date(),
      "yyyy-MM-dd HH:mm:ss",
    );

    const params = [{
      adoption_plan_id: planID,
      honey_id: 93,
      destination_country_id: "IT",
      duration_in_months: 12,
      hive_id: 3637,
      is_gift: data.gift.isGift,
      referral_url: window.location.href,
      automatic_renewal: false,
      delivered_at: formattedSendDate,
      gifted_to_email: data.gift.toEmail,
      gifted_to_fullname: data.gift.toName + " " + data.gift.toLastname,
      gifted_to_message: data.gift.message,
      international: false,
      oasi_owner_id: 151378,
      quantity: 0.5,
      shipping_date: "--",
      additionalHoneys: [],
      addons: [],
    }];

    const BASE64 = toBase64Url(JSON.stringify(params));
    window.open(`/api/createCart?context=${BASE64}&lang=${currentLanguageCode}`, "_self");
  };

  const [showWizard, setShowWizard] = useState(false);

  if (!fedezHive) return null;

  const plans = fedezHive.plans.filter(p => p.id === planID);
  const defaultPlan = plans[0];

  return (
    <StyledCard>
      <ShopCard
        hideButton={false}
        name={"Alveare Madre"}
        avatar="https://3bee-assets.s3.eu-south-1.amazonaws.com/christmas_cards/avatar_regina_fedez.png"
        backgroundImage="https://3bee-assets.s3.eu-south-1.amazonaws.com/christmas_cards/copertina_fedez.png"
        onButtonClick={() => setShowWizard(true)}
        showChildren={showWizard}
        owner={<></>}
        price={toEuro(defaultPlan.price)}
        discountedPrice={undefined}
        ratingComponent={null}
        buttonText={"Adotta"}
        videos={fedezHive.videos}
        cardBody={
          <>
            <SItem>
              <button
                onClick={() => setModalContent(<img style={{ maxWidth: "100%" }}  src="https://3bee-assets.s3.eu-south-1.amazonaws.com/christmas_cards/box_fedez.png" />)}
                className="cursor-pointer bg-transparent border-0 text-[#9CC500] font-bold text-base"
              >
                <span style={{ color: "#9CC500" }} className="underline">Kit limited edition</span>
              </button>
            </SItem>
            <SLabel
              value="SpermƏdiscoteca"
              description="Nome Regina"
            />
            <SLabel
              value="1000"
              description="Api Protette"
            />
            <SItem>
              <div className="flex mt-2">
                <div style={{ width: "3.3rem", height: "2.5rem" }} className="overflow-hidden rounded-full">
                  <img
                    src="https://3bee-assets.s3.eu-south-1.amazonaws.com/christmas_cards/avatar_ancella.png"
                    width={40}
                    height={40}
                    alt=""
                  />
                </div>
                <a href="https://www.3bee.com/owner/muschio-selvaggio/" target="_blank" className="text-base text-3bee-black max-w-[160px] text-center underline">
                  scopri la battaglia<br/>di Muschio
                </a>
              </div>
            </SItem>
          </>
        }
        shipping={undefined}
        rare={false}
      >
        <TreeWizard
          shipping={{ label: "--", value: "--" }}
          onChange={() => void 0}
          onSubmit={onSubmit}
          label={fedezHive.name}
          translations={{
            honey: {
              title: t("shopCard.hiveWizard.honey.title"),
              honeyNotIncluded: t("shopCard.hiveWizard.honey.notIncluded"),
              additionalHoneys: {
                title: t("shopCard.hiveWizard.honey.additionalHoneys.title"),
                subtitle: t(
                  "shopCard.hiveWizard.honey.additionalHoneys.subtitle",
                ),
                button: t("shopCard.hiveWizard.honey.additionalHoneys.button"),
              },
              summaryButton: t("shopCard.hiveWizard.honey.summaryButton"),
              internationalShipping: {
                title: t("shopCard.hiveWizard.internationalShipping.title"),
                priceDescription: t(
                  "shopCard.hiveWizard.internationalShipping.priceDescription",
                ),
              },
            },
            gift: {
              title: t("shopCard.hiveWizard.gift.title"),
              form: {
                byHand: t("shopCard.hiveWizard.gift.form.byHand"),
                byMail: t("shopCard.hiveWizard.gift.form.byMail"),
                chooseDate: t("shopCard.hiveWizard.gift.form.chooseDate"),
                email: t("shopCard.hiveWizard.gift.form.email"),
                name: t("shopCard.hiveWizard.gift.form.name"),
                lastname: t("shopCard.hiveWizard.gift.form.lastname"),
                message: t("shopCard.hiveWizard.gift.form.message"),
                or: t("shopCard.hiveWizard.gift.form.or"),
                sendDate: t("shopCard.hiveWizard.gift.form.sendDate"),
                title: t("shopCard.hiveWizard.gift.form.title"),
              },
              summaryButton: t("shopCard.hiveWizard.gift.form.summaryButton"),
              summaryButtonGift: t(
                "shopCard.hiveWizard.gift.form.summaryButtonGift",
              ),
            },
            conclude: {
              title: t("shopCard.hiveWizard.conclude.title"),
              duration: {
                title: t("shopCard.tree.conclude.duration.title"),
                year: t("shopCard.hiveWizard.conclude.duration.year"),
                year_plural: t(
                  "shopCard.hiveWizard.conclude.duration.year_plural",
                ),
              },
              autoRenewal: {
                title: t("shopCard.hiveWizard.conclude.autoRenewal.title"),
                description: t("shopCard.tree.conclude.autoRenewal.description"),
              },
              summaryButton:  t("shopCard.hiveWizard.conclude.summaryButton"),
              international: {
                description: "Solo area schengen",
                title: t("shopCard.hiveWizard.conclude.international.title"),
              },
              customName: {
                title: t("shopCard.tree.conclude.customName.title"),
                description: t("shopCard.tree.conclude.customName.description"),
                placeholder: t("shopCard.tree.conclude.customName.placeholder"),
              },
            },
          }}
          stepHeaders={[TreeWizard.GiftStep.header]}
          price={toEuro(defaultPlan.price)}
          isLoading={false}
          onBack={() => setShowWizard(false)}
          defaultValues={{
            conclude: { customName: "NULL", autoRenewal: false },
          }}
        >
          <TreeWizard.GiftStep
            calendarLocale={currentLanguageCode}
            hideGiftTypeSwitch
          />
        </TreeWizard>
      </ShopCard>
    </StyledCard>
  );
};

export default FedezCard;
