import styled from "styled-components";
import { Rem, BP } from "../../commons/Theme";

export const StyledFastChooseItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: ${Rem(24)};
  padding-left: ${Rem(16)};
  overflow-x: auto;

  @media (${BP.ipad}) {
    justify-content: center;
    flex-wrap: wrap;
    column-gap: ${Rem(50)};
    max-width: 1024px;
  }
`;

export const StyledCard = styled.div`
  margin-top: ${Rem(20)};
  margin-bottom: ${Rem(50)};  
`;
